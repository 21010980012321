import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useFont } from "../contexts/FontContext";
import Page from "../Components/Page";
import HomeButton from "../Components/HomeButton";
import { BlocksRenderer, BlocksContent } from "@strapi/blocks-react-renderer";

const EULA = () => {
  const fontValue = useFont();
  const font = fontValue?.font;

  const [error, setError] = useState(null);
  const [eulaData, setEulaData] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/eula?populate=*")
      .then(({ data }) => {
        setEulaData(data.data);
      })
      .catch((error) => setError(error));
  }, []);

  return (
    <Page isLoading={fontValue?.loading}>
      <div className="bg-gray-300 ">
        <div className="lg:max-w-[1100px] m-auto ">
          <div className="flex justify-center pt-10">
            <p className={`${font?.SUHTX} text-black text-center`}>
              {eulaData.attributes?.EULAHTX_1}
            </p>
          </div>

          <div className="flex justify-center pt-3 pb-5">
            <div className="lg:max-w-[1100px] m-auto  lg:py-6 py-2  lg:pt-2 ">
              <div className=" justify-start pt-2 pb-5">
                {eulaData.attributes?.EULADTX_1 != undefined ||
                eulaData.attributes?.EULADTX_1 != null ? (
                  <div className="pt-2 pl-2 pr-2">
                    <BlocksRenderer
                      content={eulaData.attributes?.EULADTX_1}
                      blocks={{
                        paragraph: ({ children }) => (
                          <span className={`${font?.DTX} text-black `}>
                            {children}
                            <br />
                          </span>
                        ),
                        list: ({ children }) => (
                          <ul className="list-disc pl-6">
                            <span className={`${font?.DTX}`}>{children}</span>
                          </ul>
                        ),
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomeButton />
    </Page>
  );
};

export default EULA;
