import React from "react";
import TopBar from "./TopBar";
import BottomBar from "./BottomBar";

const Layout = (props) => {
  return (
    <div>
      <TopBar></TopBar>
      <div className="lg:mt-20 mt-[65px]">{props.children}</div>
      <BottomBar></BottomBar>
    </div>
  );
};

export default Layout;
