import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const HomeButton = () => {
  let navigate = useNavigate();

  return (
    <div>
      <button
        onClick={() => {
          navigate("/");
        }}
        title="Go to Home Page"
        className="fixed z-90 bottom-20 right-8 bg-[#161C2E] w-20 h-20 rounded-full drop-shadow-lg flex justify-center items-center text-black text-4xl hover:bg-opacity-70 hover:drop-shadow-2xl hover:animate-bounce duration-300"
      >
        <svg
          width="50"
          height="50"
          fill="#ED8B37"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M18.121,9.88l-7.832-7.836c-0.155-0.158-0.428-0.155-0.584,0L1.842,9.913c-0.262,0.263-0.073,0.705,0.292,0.705h2.069v7.042c0,0.227,0.187,0.414,0.414,0.414h3.725c0.228,0,0.414-0.188,0.414-0.414v-3.313h2.483v3.313c0,0.227,0.187,0.414,0.413,0.414h3.726c0.229,0,0.414-0.188,0.414-0.414v-7.042h2.068h0.004C18.331,10.617,18.389,10.146,18.121,9.88 M14.963,17.245h-2.896v-3.313c0-0.229-0.186-0.415-0.414-0.415H8.342c-0.228,0-0.414,0.187-0.414,0.415v3.313H5.032v-6.628h9.931V17.245z M3.133,9.79l6.864-6.868l6.867,6.868H3.133z"></path>
        </svg>
      </button>
    </div>
  );
};

export default HomeButton;
