import React from "react";

function Validation(values) {
  const errors = {};

  const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,6}$/;

  if (values.firstName === "") {
    errors.firstName = "* First Name is required";
  }

  if (values.lastName === "") {
    errors.lastName = "* Last Name is required";
  }

  if (values.email === "") {
    errors.email = "* Email is required";
  } else if (!email_pattern.test(values.email)) {
    errors.email = "Email is not correct";
  }

  return errors;
}

export default Validation;
