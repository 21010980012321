import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { useFont } from "../contexts/FontContext";
import Page from "../Components/Page";

const HeaderIconsSm = () => {
  const [visible, setVisible] = useState(false);
  const fontValue = useFont();
  const font = fontValue?.font;
  const [error, setError] = useState(null);
  const [tbData, setTbData] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/tb?populate=*")
      .then(({ data }) => {
        setTbData(data.data);
      })
      .catch((error) => setError(error));
  }, []);

  const toggleMenu = () => {
    setVisible((prev) => !prev);
  };
  let navigate = useNavigate();

  return (
    <Page isLoading={fontValue?.loading}>
      <div className="block lg:hidden">
        <div
          className="flex hover:scale-105 duration-300 cursor-pointer"
          onClick={toggleMenu}
        >
          <div className="flex  p-2">
            <div className="p-2">
              <img
                src={
                  process.env.REACT_APP_STRAPI_API_URL +
                  tbData.attributes?.TBIMG_01?.data?.attributes?.url
                }
                alt="phone"
                className="w-10  lg:w-14 object-cover"
              />
            </div>
          </div>

          <div className="flex  p-2">
            <div className="p-2">
              <img
                src={
                  process.env.REACT_APP_STRAPI_API_URL +
                  tbData.attributes?.TBIMG_02?.data?.attributes?.url
                }
                alt="training"
                className=" w-10  lg:w-14 object-cover"
              />
            </div>
          </div>
          <div className="flex  p-2">
            <div className="p-2">
              <img
                src={
                  process.env.REACT_APP_STRAPI_API_URL +
                  tbData.attributes?.TBIMG_03?.data?.attributes?.url
                }
                alt="phone"
                className="w-10  lg:w-14 object-cover"
              />
            </div>
          </div>
          <div className="flex  p-2">
            <div className="p-2">
              <img
                src={
                  process.env.REACT_APP_STRAPI_API_URL +
                  tbData.attributes?.TBIMG_04?.data?.attributes?.url
                }
                alt="phone"
                className="w-10 lg:w-14 object-cover"
              />
            </div>
          </div>
        </div>
        {visible && (
          <div className="absolute origin-top-right right-0 z-20">
            <div className="rounded-md shadow-lg  cursor-pointer bg-[#161C30]">
              <a href={tbData.attributes?.TBLNK_1}>
                <div className="flex hover:scale-105 duration-300 p-2">
                  <div className="p-2">
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        tbData.attributes?.TBIMG_01?.data?.attributes?.url
                      }
                      alt="phone"
                      className="w-10  lg:w-14 object-cover"
                    />
                  </div>
                  <div>
                    <p className="leading-3">
                      <span
                        className={`font-raleway font-bold text-base text-white`}
                      >
                        {tbData.attributes?.TBHTX_1}
                      </span>
                      <br />
                      {tbData.attributes?.TBHDX_01?.split(/\n/).map((line) => (
                        <span
                          key={`TBHDX_01${line}`}
                          className={`font-raleway font-bold text-sm text-white `}
                        >
                          {line}
                          <br />
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              </a>
              <a href={tbData.attributes?.TBLNK_2} target="_blank">
                <div className="flex hover:scale-105 duration-300 p-2">
                  <div className="p-2">
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        tbData.attributes?.TBIMG_02?.data?.attributes?.url
                      }
                      alt="training"
                      className=" w-10  lg:w-14 object-cover"
                    />
                  </div>
                  <div>
                    <p className="leading-3">
                      <span
                        className={`font-raleway font-bold text-base text-white`}
                      >
                        {tbData.attributes?.TBHTX_2}
                      </span>
                      <br />
                      {tbData.attributes?.TBHDX_02?.split(/\n/).map((line) => (
                        <span
                          key={`TBHDX_02${line}`}
                          className={`font-raleway font-bold text-sm text-white`}
                        >
                          {line}
                          <br />
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              </a>
              <a href={tbData.attributes?.TBLNK_3}>
                <div
                  className="flex hover:scale-105 duration-300 p-2"
                  onClick={() => {
                    navigate("/meetandshare");
                  }}
                >
                  <div className="p-2">
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        tbData.attributes?.TBIMG_03?.data?.attributes?.url
                      }
                      alt="phone"
                      className="w-10  lg:w-14 object-cover"
                    />
                  </div>
                  <div>
                    <p className="leading-3">
                      <span
                        className={`font-raleway font-bold text-base text-white`}
                      >
                        {tbData.attributes?.TBHTX_3}
                      </span>
                      <br />
                      {tbData.attributes?.TBHDX_03?.split(/\n/).map((line) => (
                        <span
                          key={`TBHDX_03${line}`}
                          className={`font-raleway font-bold text-sm text-white `}
                        >
                          {line}
                          <br />
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              </a>
              <a href={tbData.attributes?.TBLNK_4}>
                <div
                  className="flex hover:scale-105 duration-300 p-2"
                  onClick={() => {
                    navigate("/meetandshare");
                  }}
                >
                  <div className="p-2">
                    <img
                      src={
                        process.env.REACT_APP_STRAPI_API_URL +
                        tbData.attributes?.TBIMG_04?.data?.attributes?.url
                      }
                      alt="phone"
                      className="w-10  lg:w-14 object-cover"
                    />
                  </div>
                  <div>
                    <p className="leading-3">
                      <span
                        className={`font-raleway font-bold text-base text-white`}
                      >
                        {tbData.attributes?.TBHTX_4}
                      </span>
                      <br />
                      {tbData.attributes?.TBHDX_04?.split(/\n/).map((line) => (
                        <span
                          key={`TBHDX_03${line}`}
                          className={`font-raleway font-bold text-sm text-white `}
                        >
                          {line}
                          <br />
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="flex w-full justify-end pr-2">
              <div
                className="rounded-md w-6 h-6 shadow-lg  cursor-pointer bg-[#161C30] text-center text-white"
                onClick={toggleMenu}
              >
                X
              </div>
            </div>
          </div>
        )}
      </div>
    </Page>
  );
};

export default HeaderIconsSm;
